import { T } from "@/locales";

export default {
  number: [
    { required: true, message: T("请输入产品编号"), trigger: "change" },
    { max: 256, message: T("超出最大长度 (256)"), trigger: "change" },
  ],
  name: [
    { required: true, message: T("请输入产品名称"), trigger: "change" },
    { max: 256, message: T("超出最大长度 (256)"), trigger: "change" },
  ],
  client: [{ required: true, message: T("请选择货主"), trigger: "change" }],
  remark: [{ max: 256, message: T("超出最大长度 (256)"), trigger: "change" }],
  pallet_capacity: [{ required: true, message: T("请输入容器容量"), trigger: "change" }],
  shelf_life_days: [{ required: true, message: T("请输入保质期"), trigger: "change" }],
  shelf_life_warning_days: [{ required: true, message: T("请输入预警天数"), trigger: "change" }],
  warning_warehouses: [{ required: true, message: T("请选择预警仓库"), trigger: "change" }],
  inventory_upper: [{ required: true, message: T("请输入库存上限"), trigger: "change" }],
  inventory_lower: [{ required: true, message: T("请输入库存下限"), trigger: "change" }],
};
